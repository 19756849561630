<template>
  <div class="row offers mt-2">
    <b-col lg="12">
      <div class="card">
        <b-row align-h="center">
          <b-col lg="6">
            <div class="p-2">
              <div class="mt-2 text-center">Search Banner</div>
              <b-input-group class="mt-3">
                <b-form-input
                  v-model="query_search"
                  type="search"
                  placeholder="Type to search"
                  @change="setQuerySearch"
                  trim
                ></b-form-input>
                <b-input-group-append>
                  <b-button type="submit" variant="primary" @click="asyncFind">Search</b-button>
                  <b-button type="button" variant="dark" class="ml-2" @click="asyncClearFind">Clear</b-button>
                  <b-spinner v-show="isLoadingSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row justify-content-end pb-1">
            <span class="pr-2">
              <router-link class="btn btn-sm btn-dark" :to="{name: BannersAddPath.name}">
                <em class="fa fa-plus"></em>
              </router-link>
            </span>
            <span>
              <button class="btn btn-sm btn-dark" @click="actionGetBaners">
                <em class="fa fa-refresh"></em>
              </button>
            </span>
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ formatPriceBasic(totalRows) }}</strong>
          </div>
          <div class="table-responsesive" >
            <TableBanners :banners="banners" :isLoading="isLoading" :currentPage="currentPage" :perPage="perPage" />
          </div>
        </div>
        <b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1 mt-2 pt-4">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
			</div>
    </b-col>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { BannersAddPath } from "../../router/marketing";
import TableBanners from "../../components/banners/banners.vue";
export default {
  name: 'banners',
  components:{
    TableBanners
  },
  data() {
    return {
      BannersAddPath,
      currentPage: 1,
      perPage: 100,
			query_search: '',
      isLoadingSearch: false,
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetBaners();
    },
  },
  computed: {
    ...mapGetters("banners", ["banners"]),
    ...mapState({
      isLoading: (state) => state.banners.isLoading,
      isError: (state) => state.banners.isError,
      totalRows: (state) => state.banners.totalRows,
      discounts: (state) => state.banners.items,
    }),
  },
  mounted() {
    this.actionGetBaners();
  },
  methods: {
    ...mapActions('banners', ['fetchBanners','fetchBannersByid','searchBanners']),
    actionGetBaners() {
      if (this.query_search == "") {
				let payload = {
					page: this.currentPage,
					limit: this.perPage,
				};
				this.fetchBanners(payload);
			} else {
				this.asyncFind();
			}
    },
		setQuerySearch(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search = query;
			}, 1000);
		},
    asyncFind() {
			this.isLoadingSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search;
				if (!query) {
					this.actionGetBaners();
					this.isLoadingSearch = false;
					return;
				}
				this.searchBanners({
					q: query,
					page: this.currentPage,
					limit: this.perPage,
				})
					.then((response) => {
						this.items = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
			}, 1000);
    },
		asyncClearFind() {
			this.query_search = "";
			this.actionGetBaners();
			this.isLoadingSearch = false;
		},
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
};
</script>