<template>
    <div>
      <b-table 
        sticky-header="500px" 
        responsive="xl"
        show-empty
        :busy="isLoading"
        hover 
        :isLoading="isLoading"
        :fields="fields"
        :items="banners">
        <template #head()="row">
          <div class="text-nowrap">
            {{ row.label }}
          </div>
        </template>
        <template #cell(No)="row">
          {{ (currentPage - 1) * perPage + (row.index + 1) }}
        </template>
        <template #cell(name)="row">
          <div  @click="actionEdit(row.item)" class="hoverEdit">
            {{ row.item.name ? row.item.name : '' }}
          </div>
        </template>
        <template #cell(client_id)="row">
          <div>
           
            <span v-if="row.item.client_id[0] === 1" class="badge badge-warning p-1">
                {{platform(row.item.client_id[0])}}
            </span>
            <span v-else-if="row.item.client_id[0] === 2" class="badge badge-success p-1">
                {{platform(row.item.client_id[0])}}
            </span>
            <span v-else-if="row.item.client_id[0] === 7" class="badge badge-danger p-1">
                {{platform(row.item.client_id[0])}}
            </span>
          </div>
        </template>
        <template #cell(banner_type)="row">
          <div>
           
            <span v-if="row.item.banner_type === 'open url'" class="badge badge-info p-1">
                {{row.item.banner_type.toUpperCase()}}
            </span>
            <span v-else-if="row.item.banner_type === 'banner only'" class="badge badge-success p-1">
                {{row.item.banner_type.toUpperCase()}}
            </span>
            <span v-else-if="row.item.banner_type === 'landing page'" class="badge badge-warning p-1">
                {{row.item.banner_type.toUpperCase()}}
            </span>
            <span v-else-if="row.item.banner_type === 'static'" class="badge badge-danger p-1">
                {{row.item.banner_type.toUpperCase()}}
            </span>
            <span v-else-if="row.item.banner_type === 'promo'" class="badge badge-secondary p-1">
                {{row.item.banner_type.toUpperCase()}}
            </span>
            <span v-else-if="row.item.banner_type === 'brand'" class="badge badge-dark p-1">
                {{row.item.banner_type.toUpperCase()}}
            </span>
            <span v-else-if="row.item.banner_type === 'buffet'" class="badge badge-light p-1">
                {{row.item.banner_type.toUpperCase()}}
            </span>

          </div>
        </template>
        <template #cell(valid_from)="row">
          <div >
            {{formatDate(row.item.valid_from)}}
          </div>
        </template>
        <template #cell(valid_to)="row"> 
          <div>
            {{formatDate(row.item.valid_to)}}
          </div>
        </template>
       
        <template v-slot:table-busy>
          <div class="text-center text-dark my-2">
            <b-spinner
              class="align-middle"
              variant="dark"
              type="grow"
              label="Loading .."
            >
            </b-spinner>
            Loading ..
          </div>
        </template>
      </b-table>
    </div>
  </template>
  <script>

  import moment from "moment";
  import { BannersEditPath } from '../../router/marketing';

    export default {
      name: 'Banners',
      props: {
        banners: {
          type: Array,
          required: true
        },
        isLoading: {
          type: Boolean,
          required: true
        },
        currentPage: {
          type: Number,
          required: true
        },
        perPage: {
          type: Number,
          required: true
        },
      },
      data() {
        return {
          showModal: false,
          fields:[
            {
              key:'No',
              label: 'No',
            },
            {
              key: 'name',
              label:'Name',
            },
            {
              key: 'client_id',
              label:'Platform',
              sortable: false,
            },
            {
              key: 'banner_type',
              label:'Banner Type',
              sortable: false,
            },
            {
              key: 'valid_from',
              label:'Valid From',
              sortable: false,
            },
            {
              key: 'valid_to',
              label:'Valid To',
              sortable: false,
            },
          ],
        }
      },
      methods: {
        platform(key) {
            if (key == 1) {
                return 'IOS'.toUpperCase();
            } else if (key == 2) {
                return 'Android'.toUpperCase();
            } else if (key == 7) {
                return 'Web Store'.toUpperCase();
            }
        },
        formatDate(tgl) {
            return moment(tgl).format('YYYY-MM-DD HH:mm:ss');
        },
        mapUrl(item) {
          if (item) {
            return BannersEditPath.name;
          }
        },
        actionEdit(item) {
        this.$router.push({
          name: this.mapUrl(item),
          params: {
            id: item.id,
          },
        })
      },

    }
  }
  </script>
  <style lang="css" scoped>
  .swal2-input[type=number] {
    max-width: 20em !important;
  }
  .hoverEdit{
    cursor:pointer;
  }
  .hoverEdit:hover {
    color : #2596be
  }
  </style>