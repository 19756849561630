var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "xl",
      "show-empty": "",
      "busy": _vm.isLoading,
      "hover": "",
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "items": _vm.banners
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " ")])];
      }
    }, {
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "hoverEdit",
          on: {
            "click": function ($event) {
              return _vm.actionEdit(row.item);
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name ? row.item.name : '') + " ")])];
      }
    }, {
      key: "cell(client_id)",
      fn: function (row) {
        return [_c('div', [row.item.client_id[0] === 1 ? _c('span', {
          staticClass: "badge badge-warning p-1"
        }, [_vm._v(" " + _vm._s(_vm.platform(row.item.client_id[0])) + " ")]) : row.item.client_id[0] === 2 ? _c('span', {
          staticClass: "badge badge-success p-1"
        }, [_vm._v(" " + _vm._s(_vm.platform(row.item.client_id[0])) + " ")]) : row.item.client_id[0] === 7 ? _c('span', {
          staticClass: "badge badge-danger p-1"
        }, [_vm._v(" " + _vm._s(_vm.platform(row.item.client_id[0])) + " ")]) : _vm._e()])];
      }
    }, {
      key: "cell(banner_type)",
      fn: function (row) {
        return [_c('div', [row.item.banner_type === 'open url' ? _c('span', {
          staticClass: "badge badge-info p-1"
        }, [_vm._v(" " + _vm._s(row.item.banner_type.toUpperCase()) + " ")]) : row.item.banner_type === 'banner only' ? _c('span', {
          staticClass: "badge badge-success p-1"
        }, [_vm._v(" " + _vm._s(row.item.banner_type.toUpperCase()) + " ")]) : row.item.banner_type === 'landing page' ? _c('span', {
          staticClass: "badge badge-warning p-1"
        }, [_vm._v(" " + _vm._s(row.item.banner_type.toUpperCase()) + " ")]) : row.item.banner_type === 'static' ? _c('span', {
          staticClass: "badge badge-danger p-1"
        }, [_vm._v(" " + _vm._s(row.item.banner_type.toUpperCase()) + " ")]) : row.item.banner_type === 'promo' ? _c('span', {
          staticClass: "badge badge-secondary p-1"
        }, [_vm._v(" " + _vm._s(row.item.banner_type.toUpperCase()) + " ")]) : row.item.banner_type === 'brand' ? _c('span', {
          staticClass: "badge badge-dark p-1"
        }, [_vm._v(" " + _vm._s(row.item.banner_type.toUpperCase()) + " ")]) : row.item.banner_type === 'buffet' ? _c('span', {
          staticClass: "badge badge-light p-1"
        }, [_vm._v(" " + _vm._s(row.item.banner_type.toUpperCase()) + " ")]) : _vm._e()])];
      }
    }, {
      key: "cell(valid_from)",
      fn: function (row) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatDate(row.item.valid_from)) + " ")])];
      }
    }, {
      key: "cell(valid_to)",
      fn: function (row) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatDate(row.item.valid_to)) + " ")])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }